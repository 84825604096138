//
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import axios from 'axios';

import {
  RowOffsetBottom,
  FieldLabel,
  SectionLabel,
  InputMain,
  Column,
  HalfContentWrapper,
  HalfContent,
  ColumnOffsetBottom,
} from '../../components/MainComponents/MainComponents';
import MapContainer from '../MapContainer/MapContainer';
import PlaceAutoCompleteComponent from '../../components/PlaceAutoCompleteComponent/PlaceAutoCompleteComponent';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import { getNames, getName } from '../../utils/country';
import OpeningTimesComponent from '../../components/OpeningTimesComponent/OpeningTimesComponent';

const sortCityByLabel = (a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

const MapAddress = (props) => {
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [mapKey, setMapKey] = useState(0);
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [openingHours, setOpeningHours] = useState([
    { day: 0, operational: false, open: '', close: '' },
    { day: 1, operational: false, open: '', close: '' },
    { day: 2, operational: true, open: '', close: '' },
    { day: 3, operational: false, open: '', close: '' },
    { day: 4, operational: false, open: '', close: '' },
    { day: 5, operational: true, open: '', close: '' },
    { day: 6, operational: false, open: '', close: '' },
  ]);

  useEffect(() => {
    setCity(props.city);
    setAddress(props.address);
    setState(props.state);
    setCountry(props.country);
    setPostcode(props.postcode);
    setLat(props.lat);
    setLng(props.lng);
    setOpeningHours(props.openingHours);
  }, [props.city]);

  useEffect(() => {
    const tempCountries = [];
    getNames().map((name) => {
      tempCountries.push({
        value: name,
        label: name,
      });
    });
    setCountries(tempCountries);
  }, []);

  useEffect(() => {
    props.handleChange('address', address);
  }, [address]);
  useEffect(() => {
    props.handleChange('city', city);
  }, [city]);
  useEffect(() => {
    props.handleChange('country', country);
  }, [country]);
  useEffect(() => {
    props.handleChange('state', state);
  }, [state]);
  useEffect(() => {
    props.handleChange('postcode', postcode);
  }, [postcode]);
  useEffect(() => {
    props.handleChange('lat', lat);
  }, [lat]);
  useEffect(() => {
    props.handleChange('lng', lng);
  }, [lng]);
  useEffect(() => {
    props.handleChange('placeId', placeId);
  }, [placeId]);

  const changeAddress = (address) => {
    setLat(address.lat);
    setLng(address.lng);
    setAddress(address.address);
    setPostcode(address.postalCode);
    setCountry(getName(address.country));
    setState(address.state);
    setCity(address.city);
    setPlaceId(address.placeId);
    onOpeningHoursChange(address.openingHours);
  };

  const onCountryChage = (item) => {
    setCountry(item.value);
  };
  const onOpeningHoursChange = (hours) => {
    props.handleChange('openingHours', hours);
  };

  return (
    <ColumnOffsetBottom>
      <Column>
        <FieldLabel>SEARCH ADDRESS</FieldLabel>
        <PlaceAutoCompleteComponent
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          placeholder="Search address"
          onChange={changeAddress}
        />
      </Column>
      <Column>
        <FieldLabel>ADDRESS LINE 1</FieldLabel>
        <InputMain
          value={address}
          name="address"
          onChange={(event) => setAddress(event.target.value)}
          maxLength={50}
          error={'address' in props.errors}
          placeholder="Business Address"
          onFocus={props.toggleFocused}
          onBlur={props.toggleFocused}
        />
      </Column>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>COUNTRY</FieldLabel>
            <DropDownComponent
              value={{
                value: country,
                label: country,
              }}
              name="country"
              error={'country' in props.errors}
              placeholder="Select your Country"
              onSelect={onCountryChage}
              options={countries}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
          <HalfContent>
            <FieldLabel>STATE</FieldLabel>
            <InputMain
              value={state}
              name="state"
              onChange={(event) => setState(event.target.value)}
              maxLength={50}
              error={'state' in props.errors}
              placeholder="state"
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
        </HalfContentWrapper>
      </Column>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>TOWN OR CITY</FieldLabel>
            <InputMain
              name="city"
              maxLength={50}
              error={'city' in props.errors}
              value={city}
              placeholder="City"
              onChange={(event) => setCity(event.target.value)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
          <HalfContent>
            <FieldLabel>POSTCODE</FieldLabel>
            <InputMain
              name="postcode"
              maxLength={8}
              error={'postcode' in props.errors}
              value={postcode}
              placeholder="Postcode"
              onChange={(event) => setPostcode(event.target.value)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
        </HalfContentWrapper>
        <RowOffsetBottom>
          <Column>
            <SectionLabel>OPENING TIMES</SectionLabel>
            <OpeningTimesComponent handleChange={onOpeningHoursChange} />
          </Column>
        </RowOffsetBottom>
        <MapContainer
          key={mapKey}
          city={{
            label: city,
            lat,
            lng,
          }}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        />
      </Column>
    </ColumnOffsetBottom>
  );
};
const ProfileMapAddressSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    city: profile.city.label,
    country: profile.country,
    state: profile.state,
    postcode: profile.postcode,
    address: profile.address,
    lat: profile.lat,
    lng: profile.lng,
    openingHours: profile.openingHours,
  })
);
const mapStateToProps = ProfileMapAddressSelector;
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapAddress);
