import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// import createSagaMiddleware from "redux-saga";
import postsReducer, { postsInitialState } from './posts/posts.reduces';
import tipsReducer, { tipsInitialState } from './tips/tips.reduces';
import postReducer, { postInitialState } from './post/post.reduces';
import authReducer, { authInitialState } from './auth/auth.reduces';
import userReducer, { userInitialState } from './user/user.reduces';
import paymentsWorker from './payments/payments.saga';
import paymentsReducer, {
  paymentsInitialState,
} from './payments/payments.reduces';
import authWorker from './auth/auth.saga';
import postsWorker from './posts/posts.saga';
import tipsWorker from './tips/tips.saga';
import postWorker from './post/post.saga';
import appReducer, { appInitialState } from './app/app.reduces';
import profileReducer, { profileInitialState } from './profile/profile.reduces';
import userWorker from './user/user.saga';
import profileWorker from './profile/profile.saga';
import statisticReducer, {
  statisticInitialState,
} from './statistic/statistic.reduces';
import { authMiddleware } from './middlewares/auth';
import { crashReporter } from './middlewares/crashReporting';
import appWorker from './app/app.saga';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  app: appReducer,
  post: postReducer,
  statistic: statisticReducer,
  auth: authReducer,
  posts: postsReducer,
  tips: tipsReducer,
  profile: profileReducer,
  user: userReducer,
  payments: paymentsReducer,
});

export const rootInitialState = {
  app: appInitialState,
  post: postInitialState,
  statistic: statisticInitialState,
  posts: postsInitialState,
  tips: tipsInitialState,
  profile: profileInitialState,
  user: userInitialState,
  auth: authInitialState,
  payments: paymentsInitialState,
};

export function* rootSaga() {
  yield all([
    appWorker(),
    paymentsWorker(),
    authWorker(),
    postsWorker(),
    tipsWorker(),
    postWorker(),
    profileWorker(),
    userWorker(),
  ]);
}

let middleware = [sagaMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

const store = createStore(
  rootReducer,
  rootInitialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export default store;
