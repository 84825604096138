import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import styled from 'styled-components';
import MapContainer from '../MapContainer/MapContainer';
import PlaceAutoCompleteComponent from '../../components/PlaceAutoCompleteComponent-v2/PlaceAutoCompleteComponent';
import DropDownComponent from '../../components/DropDownComponent-v2/DropDownComponent';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import { getNames, getName } from '../../utils/country';
import { StyledErrorMessage } from '../../components/MainComponents/MainComponents';
import { newTheme } from '../../mainStyles';

const sortCityByLabel = (a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

const MapAddress = ({
  temp,
  errorsMessages,
  touched,
  handleTouched,
  handleChange,
  ...props
}) => {
  const [countries, setCountries] = useState([]);
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    const tempCountries = [];
    getNames().map((name) => {
      tempCountries.push({
        value: name,
        label: name,
      });
    });
    setCountries(tempCountries);
  }, []);

  const changeAddress = (address) => {
    handleChange('lat', address.lat);
    handleChange('lng', address.lng);
    handleChange('address', address.address);
    handleChange('postcode', address.postalCode);
    handleChange('country', getName(address.country));
    handleChange('state', address.state);
    handleChange('city', address.city);
    handleChange('openingHours', address.openingHours);
    handleChange('placeId', address.placeId);
  };
  const onCountryChage = (item) => {
    handleChange('country', item.value);
  };

  return (
    <>
      <StyledSection>
        <StyledInputLabel>Find Address</StyledInputLabel>
        <PlaceAutoCompleteComponent
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          placeholder="Search address"
          onChange={changeAddress}
        />
        {!!errorsMessages.coordinates && (
          <StyledErrorMessage>{errorsMessages.coordinates}</StyledErrorMessage>
        )}
      </StyledSection>
      <StyledSection>
        <StyledInputLabel>Address Line 1</StyledInputLabel>
        <StyledInput
          value={temp.address}
          name="address"
          onChange={(event) => handleChange('address', event.target.value)}
          maxLength={50}
          // error={'address' in props.errors}
          placeholder="Enter address line 1"
          onFocus={props.toggleFocused}
          // onBlur={props.toggleFocused}
          onBlur={(e) => handleTouched('address')}
          errorMessage={
            (touched.address && errorsMessages.address) ||
            (touched.address && !temp.address)
          }
        />
        {!!errorsMessages.address && (
          <StyledErrorMessage>{errorsMessages.address}</StyledErrorMessage>
        )}
      </StyledSection>
      <StyledSection flex>
        <StyledHalfSection>
          <StyledInputLabel>Country</StyledInputLabel>
          <DropDownComponent
            value={{
              value: temp.country,
              label: temp.country,
            }}
            name="country"
            placeholder="Select your Country"
            onSelect={onCountryChage}
            options={countries}
            onFocus={props.toggleFocused}
            onBlur={(e) => handleTouched('country', e.target.value)}
            errorMessage={
              (touched.country && errorsMessages.country) ||
              (touched.country && !temp.country)
            }
          />
          {!!errorsMessages.country && (
            <StyledErrorMessage>{errorsMessages.country}</StyledErrorMessage>
          )}
        </StyledHalfSection>
        <StyledHalfSection>
          <StyledInputLabel>State</StyledInputLabel>
          <StyledInput
            value={temp.state}
            name="state"
            onChange={(event) => handleChange('state', event.target.value)}
            maxLength={50}
            placeholder="State"
            onFocus={props.toggleFocused}
            onBlur={(e) => handleTouched('state', e.target.value)}
            errorMessage={
              (touched.state && errorsMessages.state) ||
              (touched.state && !temp.state)
            }
          />
          {!!errorsMessages.state && (
            <StyledErrorMessage>{errorsMessages.state}</StyledErrorMessage>
          )}
        </StyledHalfSection>
      </StyledSection>
      <StyledSection flex>
        <StyledHalfSection>
          <StyledInputLabel>Town/City</StyledInputLabel>
          <StyledInput
            name="city"
            maxLength={50}
            value={temp.city}
            placeholder="Town/City"
            onChange={(event) => handleChange('city', event.target.value)}
            onFocus={props.toggleFocused}
            onBlur={props.toggleFocused}
          />
        </StyledHalfSection>
        <StyledHalfSection>
          <StyledInputLabel>Postal Code</StyledInputLabel>
          <StyledInput
            name="postcode"
            maxLength={8}
            value={temp.postcode}
            placeholder="Postal Code"
            onChange={(event) => handleChange('postcode', event.target.value)}
            onFocus={props.toggleFocused}
            onBlur={(e) => handleTouched('postcode', e.target.value)}
            errorMessage={
              (touched.postcode && errorsMessages.postcode) ||
              (touched.postcode && !temp.postcode)
            }
          />
          {!!errorsMessages.postcode && (
            <StyledErrorMessage>{errorsMessages.postcode}</StyledErrorMessage>
          )}
        </StyledHalfSection>
      </StyledSection>
      <StyledSection>
        {temp.city && temp.lat && temp.lng && (
          <MapContainer
            key={mapKey}
            city={{
              label: temp.city,
              lat: temp.lat,
              lng: temp.lng,
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          />
        )}
      </StyledSection>
    </>
  );
};
const ProfileMapAddressSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    city: profile.city.label,
    country: profile.country,
    state: profile.state,
    postcode: profile.postcode,
    address: profile.address,
    lat: profile.lat,
    lng: profile.lng,
    openingHours: profile.openingHours,
  })
);
const mapStateToProps = ProfileMapAddressSelector;
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapAddress);

export const StyledSection = styled.div`
  margin-bottom: 16px;

  ${({ flex }) => flex && `display: flex; justify-content: space-between;`};
`;

export const StyledHalfSection = styled.div`
  width: 48%;
`;

export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

export const StyledInput = styled.input`
  font-family: ${newTheme.fontFamily.primary.light};
  width: 100%;
  padding: 17px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  color: ${({ value }) => (value ? '#131312' : '#959492')};
  /* border: 1px solid #959492; */
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}

  border: 1px solid ${({ errors }) => (errors ? `red;` : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? `red;` : '#DFDFDE;'};
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? `red;` : '#DFDFDE;')};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px;`}

	&:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
    outline: none;
  }
  &:active {
    border: 1px solid black;
  }
  &:focus-visible {
    border: 1px solid black;
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
    padding-left: 34px;
  }
`;
