import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  RowOffsetBottom,
  FieldLabel,
  SectionLabel,
  InputMain,
  Column,
} from '../../components/MainComponents/MainComponents';
import { ProfileEditingWrapper } from './styles';
import ImagePickerComponent from '../../components/ImagePickerComponent/ImagePickerComponent';
import MapAddress from '../MapAdress/MapAdress';
import TextAreaComponent from '../../components/TextAreaComponent/TextAreaComponent';
import CoffeeSetupComponent from '../../components/CoffeeSetupComponent/CoffeeSetupComponent';
import {
  selectProfileErrors,
  selectProfileObject,
  selectProfileMessage,
  selectProfilePending,
} from '../../redux/profile/profile.selectors';
import profileActions from '../../redux/profile/profile.actions';
import TopFeaturesComponent from '../../components/TopFeaturesComponent/TopFeaturesComponent';
import ImageHighlightsComponent from '../../components/ImageHighlightsComponent/ImageHighlightsComponent';

class ProfileEditingContainer extends Component {
  state = {
    focused: false,
    name: this.props.name,
  };

  toggleFocused = () => this.setState({ focused: !this.state.focused });

  handleChange = (key, value) => {
    if (key === 'name') {
      this.setState({ name: value });
    }
    this.props.tempChange(key, value);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({ name: this.props.name });
    }
  }

  render() {
    const mapData = {
      city: this.props.city.label,
      state: this.props.state,
      country: this.props.country,
      lat: this.props.lat,
      lng: this.props.lng,
      address: this.props.address,
      postcode: this.props.postcode,
    };
    return (
      <ProfileEditingWrapper>
        <RowOffsetBottom>
          <Column>
            <FieldLabel>BUSINESS NAME</FieldLabel>
            <InputMain
              error={this.props.errors ? 'name' in this.props.errors : false}
              name="name"
              maxLength={50}
              onChange={(event) =>
                this.handleChange('name', event.target.value)
              }
              value={this.state.name}
              placeholder="Business Name"
            />
          </Column>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <FieldLabel>
            <FieldLabel>LOGO</FieldLabel>
            <ImagePickerComponent
              imageWidth={154}
              error={this.props.errors ? 'logo' in this.props.errors : false}
              message={this.props.errors ? this.props.errors.logo : ''}
              defaultValue={this.props.logo}
              imageHeight={154}
              onChange={(value) => this.handleChange('logo', value)}
              label="Upload your logo here. If you do not have a logo leave this blank (Min.300px x 300px)."
              name="logo"
              imageStyles={{ borderRadius: 77 }}
            />
          </FieldLabel>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <FieldLabel>
            <FieldLabel>BACKGROUND IMAGE</FieldLabel>
            <ImagePickerComponent
              imageWidth={320}
              ratio={10 / 7}
              error={
                this.props.errors
                  ? 'backgroundImage' in this.props.errors
                  : false
              }
              message={this.props.errors.backgroundImage}
              onChange={(value) => this.handleChange('backgroundImage', value)}
              defaultValue={this.props.backgroundImage}
              imageHeight={154}
              label="Upload your cover photo. If you do not have one leave this blank (Min. 1000px x 700px)"
              name="backgroundImage"
            />
          </FieldLabel>
        </RowOffsetBottom>

        <RowOffsetBottom>
          <Column>
            <FieldLabel>DESCRIPTION</FieldLabel>
            <TextAreaComponent
              name="description"
              defaultValue={this.props.description}
              maxLength={200}
              onChange={(value) => this.handleChange('description', value)}
              error={
                this.props.errors ? 'description' in this.props.errors : false
              }
              placeholder="What do you want to tell customers about your venue..."
            />
          </Column>
        </RowOffsetBottom>
        <RowOffsetBottom>
          <Column>
            <SectionLabel>COFFEE SETUP</SectionLabel>
            <CoffeeSetupComponent handleChange={this.handleChange} />
          </Column>
        </RowOffsetBottom>
        <RowOffsetBottom>
          <Column>
            <SectionLabel>TOP FEATURES</SectionLabel>
            <TopFeaturesComponent handleChange={this.handleChange} />
          </Column>
        </RowOffsetBottom>
        <RowOffsetBottom>
          <Column>
            <SectionLabel>Highlights</SectionLabel>
            <FieldLabel> ADD UP TO THREE HIGHLIGHTS TO YOUR PROFILE</FieldLabel>
            <ImageHighlightsComponent
              data={this.props.highlights}
              handleChange={this.handleChange}
            />
          </Column>
        </RowOffsetBottom>
        <MapAddress
          temp={mapData}
          toggleFocused={this.toggleFocused}
          focused={this.state.focused}
          handleChange={this.handleChange}
        />
      </ProfileEditingWrapper>
    );
  }
}

const profileEditionSelector = createSelector(
  selectProfileErrors,
  selectProfileMessage,
  selectProfilePending,
  selectProfileObject,
  (errors, message, pending, profile) => ({
    errors,
    message,
    pending,
    ...profile,
  })
);

const mapStateToProps = profileEditionSelector;

const mapDispatchToProps = {
  tempChange: profileActions.changeTemp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditingContainer);
